import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const CompanyInstructionsContext = createContext();

export const useCompanyInstructionsContext = () => useContext(CompanyInstructionsContext);

export const CompanyInstructionsContextProvider = ({ children }) => {
  const [companyInstructions, setCompanyInstructions] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API_CORE_SERVICES;

  const getCompanyInstructions = async (username) => {
    setIsLoading(true);
    try {
      const apiUrl = `${baseUrl}/api/get-company-instruction`;
      const reqBody = {
        instruction: 'Sample Instruction Text'
      }
      const response = await axios.get(apiUrl, reqBody, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.data) {
        setIsLoading(false);
        throw new Error('Failed to get response data');
      }

      setIsLoading(false);
      setCompanyInstructions(response.data?.instruction ? response.data?.instruction : response.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  }

  const updateCompanyInstructions = async (name, companyInstructions) => {
    setIsLoading(true);
    try {
      const apiUrl = `${baseUrl}/api/create-company-instruction`;

      const reqBody = {
        instruction: companyInstructions,
        created_by: name,
        updated_by: name
      }
      const response = await axios.post(apiUrl, reqBody, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.data) {
        setIsLoading(false);
        throw new Error('Failed to get response data');
      }

      setIsLoading(false);
      setCompanyInstructions(companyInstructions);
    } catch (error) {
      console.error('Error:', error.message);
    }
  }

  const contextValue = {
    getCompanyInstructions,
    setCompanyInstructions,
    updateCompanyInstructions,
    companyInstructions,
    isLoading,
    setIsLoading
  };

  return (
    <CompanyInstructionsContext.Provider value={contextValue}>
      {children}
    </CompanyInstructionsContext.Provider>
  );
}