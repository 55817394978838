import React, { createContext, useContext } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { NOVA_THEME } from '../constants/themeConstants'

const ProductThemeContext = createContext();
const novaTheme = createTheme(NOVA_THEME);

export const ProductThemeProvider = ({ children }) => {
  const productTheme = {
    theme: NOVA_THEME
  }
  
  return (
    <ProductThemeContext.Provider value={productTheme}>
      <ThemeProvider theme={novaTheme} >
        {children}
      </ThemeProvider>
    </ProductThemeContext.Provider>
  )
}

export const useProductThemeContext = () => useContext(ProductThemeContext);