import React from 'react';

import Typography from '@mui/material/Typography';
import { useSSOContext } from '../../lib/contextProvider/SSOContext';
import styled from 'styled-components';
import { useProductThemeContext } from '../../lib/contextProvider/ThemeProvider';



export const UsernameInnerSpan = styled('span')(({ theme }) => ({
    textTransform: "capitalize"
}));

const ChatHeading = () => {
    const { theme } = useProductThemeContext()
    const { username } = useSSOContext();

    const index = username.indexOf('.');
    const name = username.substring(0, index);

    return (
        <Typography paragraph variant="subtitle1" sx={{ color: theme.palette.primary.logoText, textAlign: 'left', marginTop: "80px" }}>
            Welcome to NOVA, <UsernameInnerSpan>{name}</UsernameInnerSpan>.
        </Typography>
    )
}

export default ChatHeading