import { CustomCardsContextProvider } from './CustomCardsContext';
import CustomCardsWrapper from './CustomCardsWrapper';

export const CustomCards = () => {
  return (
    <>
      <CustomCardsContextProvider>
        <CustomCardsWrapper/>
      </CustomCardsContextProvider>
    </>
  );
};