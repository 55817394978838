import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { UserLogsTypography } from '../UserLogs/UserLogsStyledComponents';
import { useSSOContext } from '../../lib/contextProvider/SSOContext';
import { useCustomCardsContext } from './CustomCardsContext';

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#fff'),
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#f00',
  },
}));
//color: rgb(255, 255, 255)
const Item = styled(Paper)(({ theme }) => ({
  textColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const CustomCardsGrid = () => {
  const {
    deleteCard,
    getCards,
    handleSearchChange,
    handleClick:contextHandleClick,
    activeIndex,
    filterName,
    isLoading,
    cardData,
    category
  } = useCustomCardsContext()
  const theme = useTheme();
  const [saveData, setSaveData] = useState(cardData);
  const [filteredData, setFilteredData] = useState(saveData);

  const {
    fetchGroupIds,
  } = useSSOContext();

  useEffect(() => {
    fetchGroupIds();
  }, []);

  useEffect(() => {
    if (Object.keys(saveData).length === 0) {
      getCards();
      console.log("Fetching cards from API");
    } else {
      setSaveData(cardData);
      console.log("Using saved data");
    }
  }, [category, cardData]);

  useEffect(() => {
    if (Object.keys(cardData).length !== 0) {
      setSaveData(cardData);
      setFilteredData(cardData);
    }
  }, [cardData]);

  const OnDelete = (event) => {
    deleteCard(event.currentTarget.attributes.datavalue.value);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={2}><UserLogsTypography>Id</UserLogsTypography></Grid>
        <Grid xs={2}><UserLogsTypography>Card Name</UserLogsTypography></Grid>
        <Grid xs={2}><UserLogsTypography>Description</UserLogsTypography></Grid>
        <Grid xs={2}><UserLogsTypography>Prompt</UserLogsTypography></Grid>
        <Grid xs={2}><UserLogsTypography>Accessibility</UserLogsTypography></Grid>
        <Grid xs={2}></Grid>
        {cardData['Custom Card']?.map((card) => (
          <React.Fragment key={card.id}>
            <Grid xs={2} color={'#FFFFFF'}>{card.id}</Grid>
            <Grid xs={2} color={'#FFFFFF'}>{card.card_name}</Grid>
            <Grid xs={2} color={'#FFFFFF'}>{card.description}</Grid>
            <Grid xs={2} color={'#FFFFFF'}>{card.prompt}</Grid>
            <Grid xs={2} color={'#FFFFFF'}>{card.is_active ? 'Public' : 'Private'}</Grid>
            <Grid xs={2}><DeleteButton datavalue={card.id} onClick={OnDelete} size="small">Delete</DeleteButton></Grid>
          </React.Fragment>
      ))}
      </Grid>
    </Box>
  );
};