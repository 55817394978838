import { AnalyticsContextProvider } from './AnalyticsContext';
import { AnalyticsWrapper } from './AnalyticsWrapper';

export const Analytics = () => {
  return (
    <>
      <AnalyticsContextProvider>
        <AnalyticsWrapper/>
      </AnalyticsContextProvider>
    </>
  );
};