import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { UserDateButton, UserLogsHeader, UserLogsTypography } from '../UserLogs/UserLogsStyledComponents';
import { CustomDatePicker } from '../UserLogs/UserLogsFilter';

export const AnalyticsLineChart = ({fetchData, formData, handleFieldChange}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const currentDate = new Date();
    const pastThreeMonthsDate = new Date();
    pastThreeMonthsDate.setMonth(currentDate.getMonth() - 3);
    const startDate = pastThreeMonthsDate.toISOString().split('T')[0];
    const endDate = currentDate.toISOString().split('T')[0];

    async function asyncFetchData() {
      const lastThreeMonthsData = await fetchData(startDate, endDate);
      setData(lastThreeMonthsData);
    }
    asyncFetchData();
  }, [fetchData]);

  const { selectedDateFrom, selectedDateTo } = formData;

  const applyFilter = async () => {
    const analyticsData = await fetchData(selectedDateFrom.toISOString(), selectedDateTo.toISOString());
    setData(analyticsData);
  }

  const theme = useTheme();

  return (
    <>
      <UserLogsHeader>Analytics</UserLogsHeader>
      <Box display="flex" alignItems="center" gap={2} marginBottom='10px' marginLeft='30px'>
        <UserLogsTypography>From</UserLogsTypography>
        <div style={{ marginTop: '20px', background: `${theme.palette.primary.dark}`, width: "14%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                    value={selectedDateFrom}
                    onChange={(date) => handleFieldChange('selectedDateFrom', date)}
                />
            </LocalizationProvider>
        </div>
        <UserLogsTypography >To</UserLogsTypography>
        <div style={{ marginTop: '20px', background: `${theme.palette.primary.dark}`, width: "14%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                    value={selectedDateTo}
                    onChange={(date) => handleFieldChange('selectedDateTo', date)}
                />
            </LocalizationProvider>
        </div>
        <UserDateButton onClick={applyFilter}>Apply Filter</UserDateButton>
      </Box>
      <Box>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="API Calls" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </>
  )
}
export default AnalyticsLineChart