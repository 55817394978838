import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';

const drawerWidth = 211;

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: '#EEEBDA',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19.36px',
    cursor: 'pointer'
  },
}));

export const ImageWrappper = styled('div')(({ theme }) => ({
  margin: '0 9px 0 16px',
  textAlign: 'center'
}))

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth + 10}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    background: theme.palette.backgroundColor.gradient,
    padding: '79px',
    height: '100vh',
    overflowY:'auto',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: '-54px',
    }),
  }),
);

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export const SideBarPrimaryGroup = styled('table')(({ theme }) => ({
  marginTop: '23px',
  marginLeft: '8px'
}));

export const SideBarSecondaryGroup = styled('table')(({ theme }) => ({
  marginTop: '25px',
  marginLeft: '10px'
}));

export const SideBarSettingsGroup = styled('table')(({ theme }) => ({
  position: 'fixed',
  bottom: '50px',
  marginLeft: '10px'
}));

export const CardLibraryButton = styled(Button)(({ theme }) => ({
  fontWeight: '700',
  textTransform: 'none',
  borderRadius: '0px',
  position: 'fixed',
  right: '78px',
  height: '29px',
  width:'149px',
  top: '4px',
  color:theme.palette.primary.contrastColor,
  border:`1px solid ${theme.palette.primary.contrastColor}`
}));

export const CustomCardButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'none',
  fontWeight: '700',
  width: '167px',
  height: '67px',
  gap: '0px',
  opacity: '0px',
  marginLeft: '27px',
}));