import { UserLogsHeader } from '../UserLogs/UserLogsStyledComponents';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { CompanyInstructionsInput } from './CompanyInstructionsInput';
import { useCompanyInstructionsContext } from './CompanyInstructionsContext';
import { useSSOContext } from '../../lib/contextProvider/SSOContext';

export default function CompanyInstructionsWrapper() {
  const { companyInstructions, updateCompanyInstructions } = useCompanyInstructionsContext();
  const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#0F0',
    },
  }));

  const {
    username
  } = useSSOContext();

  const onSave = (username) => {
    const index = username.indexOf('@');
    const name = username.substring(0, index);
    updateCompanyInstructions(name, companyInstructions);
  }

	return (
		<>
      <UserLogsHeader>Settings</UserLogsHeader>
      <>
        <CompanyInstructionsInput />
      </>
      <SaveButton onClick={() => onSave(username)}>Save</SaveButton>
		</>
	)
}