import React, { useState, useEffect, createContext, useContext } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';

const SSOContext = createContext();

export const SSOContextProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [groupIds, setGroupIds] = useState([]);
  const { instance, accounts } = useMsal();

  const baseUrl = process.env.REACT_APP_REDIRECT_URI;

  async function fetchGroupIds() {
    const response = await instance.acquireTokenSilent({
      scopes: ['User.Read', 'openid', 'email', 'profile'],
      account: accounts[0] // Assuming only one account is available
    });
    const accessToken = response.accessToken;

    const endpoint = 'https://graph.microsoft.com/v1.0/me/memberOf';
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);

    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const groupIds = data.value.map(group => group.id);
      setGroupIds(groupIds);
    } catch (error) {
      console.error('Fetch Error: ', error.message);
      throw error;
    }
  }

  useEffect(() => {
    if (accounts.length > 0 && instance) {
      setUsername(accounts[0].username);
    }
  })
  // Define your authentication request
  const loginRequest = {
    scopes: ["User.Read", "openid", "email", 'profile'],
    prompt: 'login'
  };

  // Use the msalInstance for authentication
  const { login, error: loginError } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

  // Effect to trigger authentication when the component mounts or when login error occurs
  useEffect(() => {
    if (loginError instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest);
    }
  }, [loginError]);

  // Logout function
  const handleLogout = async () => {
    try {
      await instance.handleRedirectPromise();

      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const logoutRequest = {
          postLogoutRedirectUri: baseUrl
        }
        // Perform logout
        instance.logout(logoutRequest);
      } else {
        console.log("No user is currently signed in.");
      }
    } catch (error) {
      console.error("Error during logout", error);
    }
  };

  const SSOContextValue = {
    accounts,
    username,
    groupIds,
    fetchGroupIds,
    login,
    handleLogout,
    loginError
  };

  return (
    <SSOContext.Provider value={SSOContextValue}>
      {children}
    </SSOContext.Provider>
  );
};
export const useSSOContext = () => useContext(SSOContext);