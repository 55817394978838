import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { svgIcon } from '../../lib/assets/svg/images';
import { useUserLogContext } from './UserLogContext';
import {
    CategoryType, ChatContentMainDiv, ChatImageHistoryWrapper, ChatSelectionMainDiv,
    UserChatSelectionDiv, UserLogsDate,
    UserLogsTime, UserName
} from './UserLogsStyledComponents';

export const UserLogsChatComponent = () => {
    const theme = useTheme();
    const { chatInfo, chatSideBarDetails, selectedIndex, handleChatSelection } = useUserLogContext();
    const processUserName = (userName) => {
        const index = userName.indexOf('@');
        const name = userName.substring(0, index);
        const fullName = name.replace(/\./g, ' ').replace(/\d+/g, '');
        const capitalizedFullName = fullName
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        return capitalizedFullName;
    };
    return (
        <>
            <div style={{ display: "flex" }}>
                <ChatSelectionMainDiv>
                    {chatSideBarDetails.map((item, index) => (
                        <UserChatSelectionDiv
                            key={index}
                            isSelected={index === selectedIndex}
                            onClick={() => handleChatSelection(index, item)}
                        >
                            <CategoryType>{item.chat_type}</CategoryType>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '88%' }}>
                                <UserName>{processUserName(item.user_name)}</UserName>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <UserLogsDate>{item.date}</UserLogsDate>
                                    <UserLogsTime>{item.time}</UserLogsTime>
                                </div>
                            </div>
                        </UserChatSelectionDiv>
                    ))}
                </ChatSelectionMainDiv>
                <ChatContentMainDiv >
                    <div style={{ marginTop: '30px' }}>
                        {chatInfo.map((item, index) => (
                            <div
                                key={index}
                                style={item.role === 'user' ? { width: '90%', textAlign: 'end', marginBottom: '37px' } : { marginBottom: '37px', width: '90%', marginLeft: '30px' }}
                            >
                                <Box component="div" sx={{ color: theme.palette.primary.logoText }}>
                                    <Typography sx={{ fontSize: "18px", fontWeight: "700", lineHeight: "26.63px", paddingBottom: "10px" }}>
                                        {item.role === 'user' ? item.userName : "NOVA"}
                                    </Typography>
                                </Box>
                                
                                {item.image !== null && (
                                    <Box component="div">
                                        <ChatImageHistoryWrapper src={item.image} alt="image" />
                                    </Box>)}
                                {item.filename  !== null &&  item.filename !==''&& (
                                    <Box component="div">
                                        {svgIcon['file']()}
                                    </Box>
                                ) }
                                <Box component="div" sx={{ color: theme.palette.primary.contrastColor }}>
                                    {item.isError ? (
                                        <Typography variant="chatText" sx={{ color: 'red' }}>
                                            {item.content}
                                        </Typography>
                                    ) : (
                                        <Typography variant="chatText" sx={{ whiteSpace: "pre-wrap" }}>
                                            {item.content}
                                        </Typography>
                                    )}
                                </Box>
                            </div>
                        ))}
                    </div>
                </ChatContentMainDiv>

            </div>
        </>
    )
}

