import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { NAVIGATION_ITEMS } from '../../lib/constants/constants';
import { svgIcon } from '../../lib/assets/svg/images';
import {
  Main,
  AppBar,
  StyledDrawer,
  DrawerHeader,
  ImageWrappper,
  SideBarSettingsGroup,
  SideBarPrimaryGroup,
} from './SideMenuStyledComponents';
import { useSideMenuContext } from './SideMenuContext';

const {
  ANALYTICS,
  COMPANY_INSTRUCTIONS,
  CUSTOM_CARDS,
  LOGOUT,
  USER_LOGS
} = NAVIGATION_ITEMS;

const SideDrawerGroupTile = ({ path, label }) => {
  const navigate = useNavigate();

  return (
    <tr onClick={() => navigate(path)}>
      <td><ImageWrappper>{svgIcon[path]()}</ImageWrappper></td>
      <td><ListItemText primary={label} /></td>
    </tr>
  )
}
const SideMenu = ({ children}) => {
  
  const { open, handleDrawerOpen, handleDrawerClose } = useSideMenuContext();
  const navigate = useNavigate();
  const theme = useTheme();
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ bgcolor: 'primary.dark', height: '57px' }} position="fixed" open={open}>
        <Toolbar sx={{marginTop:'-3px'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            {svgIcon['menuIcon']()}
          </IconButton>
          <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} onClick={() => navigate('/')}>
            {svgIcon['novaLogo']()}
            <Typography variant="logo" sx={{ ml: '10px' }} color="primary.logoText">NOVA ADMIN</Typography>
          </Box>
          {/* <>{username}</> */}
        </Toolbar>
      </AppBar>
      <StyledDrawer
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' &&
              svgIcon['drawerMenuIcon']()
            }
          </IconButton>
        </DrawerHeader>
        <List>
          <SideBarPrimaryGroup>
            <SideDrawerGroupTile path={ANALYTICS.path} label={ANALYTICS.label} />
          </SideBarPrimaryGroup>
          <SideBarPrimaryGroup>
            <SideDrawerGroupTile path={COMPANY_INSTRUCTIONS.path} label={COMPANY_INSTRUCTIONS.label} />
          </SideBarPrimaryGroup>
          <SideBarPrimaryGroup>
            <SideDrawerGroupTile path={CUSTOM_CARDS.path} label={CUSTOM_CARDS.label} />
          </SideBarPrimaryGroup>
          <SideBarPrimaryGroup>
            <SideDrawerGroupTile path={USER_LOGS.path} label={USER_LOGS.label} />
          </SideBarPrimaryGroup>
          <SideBarSettingsGroup>
            <SideDrawerGroupTile path={LOGOUT.path} label={LOGOUT.label} />
          </SideBarSettingsGroup>
        </List>
      </StyledDrawer>
      <Main open={open}>
        {children}
      </Main>
    </Box>
  );
}

export default SideMenu;