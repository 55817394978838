import React, { createContext, useContext, useState } from 'react';

const SideMenuContext = createContext();

export const useSideMenuContext = () => {
    return useContext(SideMenuContext);
  };
  
export const SideMenuProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
 
const contextValue = { 
    open, 
    setOpen,
    handleDrawerOpen,
    handleDrawerClose
 }

  return (
    <SideMenuContext.Provider value={contextValue}>
          {children}
    </SideMenuContext.Provider>
  );
};
 