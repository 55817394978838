import { CompanyInstructionsContextProvider } from './CompanyInstructionsContext';
import CompanyInstructionsWrapper from './CompanyInstructionsWrapper';

export const CompanyInstructions = () => {
  return (
    <>
      <CompanyInstructionsContextProvider>
        <CompanyInstructionsWrapper />
      </CompanyInstructionsContextProvider>
    </>
  );
};