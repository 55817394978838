import {
  ANALYTICS,
  COMPANY_INSTRUCTIONS,
  CUSTOM_CARDS,
  LOGOUT,
  USER_LOGS
} from './paths';

export const NAVIGATION_ITEMS = {
  ANALYTICS: {
    path: ANALYTICS,
    label: 'Analytics',
  },
  COMPANY_INSTRUCTIONS: {
    path: COMPANY_INSTRUCTIONS,
    label: 'Company Instructions',
  },
  CUSTOM_CARDS: {
    path: CUSTOM_CARDS,
    label: 'Custom Cards',
  },
  LOGOUT: {
    path: LOGOUT,
    label: 'Log Out',
  },
  USER_LOGS: {
    path: USER_LOGS,
    label: 'User Logs',
  },
}