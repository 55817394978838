import { UserLogsChatComponent } from "./UserLogChatComponent";
import { UserLogsFilter } from "./UserLogsFilter";
import { useUserLogContext } from './UserLogContext';
import { Backdrop, CircularProgress } from "@mui/material";
import { useTheme } from '@mui/material/styles';

export const UserLogsWrapper = () => {
  const theme = useTheme();
  const { formData, chatInfo, chatSideBarDetails, handleFieldChange, isLoading } = useUserLogContext();
  return (
    <>
      <UserLogsFilter
        formData={formData}
        handleFieldChange={handleFieldChange}
      />
      <Backdrop open={isLoading} style={{ zIndex: theme.zIndex.drawer + 1 }}>
        <CircularProgress style={{ color: theme.palette.primary.contrastText }} />
      </Backdrop>
      {!isLoading && <UserLogsChatComponent chatInfo={chatInfo} chatSideBarDetails={chatSideBarDetails} />}
    </>
  );
};
