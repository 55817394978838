import menuIcon from './menuIcon.svg';
import drawerMenuIcon from './drawerMenuIcon.svg';
import novaLogo from './novaLogo.svg';
import adminLogs from './adminLogs.svg';
import userLogs from './userLogs.svg';
import logout from './logout.svg';
import send from './send.svg';
import sendInput from './sendInput.svg';
import addIcon from './addIcon.svg';
import file from './file.svg';
import closeIcon from './closeIcon.svg';
import closeBoxIcon from './closeBoxIcon.svg';
import folderAttachment from './folderAttachment.svg';
import deleteIcon from './deleteIcon.svg';
import search from './search.svg';
import upload from './upload.svg';
import citation from './citation.svg';
import regenerate from './regenerate.svg';
import copy from './copy.svg';
import backward from './backward.svg';
import forward from './forward.svg';
import searchIconLarge from './searchIconLarge.svg'
import plusIcon from './plusIcon.svg'
import tick from './tick.svg'
import sendButtonColor from './sendButtonColor.svg'
import PaperClipColor from './PaperClipColor.svg'
import arrowIconRight from './arrowIconRight.svg'
import arrowIconLeft from './arrowIconLeft.svg'
import upArrow from './upArrow.svg'
import downArrow from './downArrow.svg'
import {
  HOME,
  ANALYTICS,
  COMPANY_INSTRUCTIONS,
  CUSTOM_CARDS,
  LOGOUT,
  USER_LOGS
} from '../../constants/paths'
import dropDownArrow from './dropDownArrow.svg'
import analytics from './analytics.svg';
import customCards from './customCards.svg';

export const svgIcon = {
  'citation':() => <img alt="citation" src ={citation} />,
  'regenerate':() => <img alt="regenerate" src ={regenerate} />,
  'copy':() => <img alt="copy" src ={copy} />,
  'backward':() => <img alt="back" src ={backward} />,
  'forward':() => <img alt="next" src ={forward} />,
  'close': () => <img alt="closeIcon" src ={closeIcon} />,
  'closeIcon': () => <img alt="closeIcon" src ={closeBoxIcon} />,
  'drawerMenuIcon': () => <img alt="menu" src={drawerMenuIcon} />,
  'arrowIconRight':()=><img alt="arrowIcon" src={arrowIconRight}/>,
  'arrowIconLeft':()=><img alt="arrowIcon" src={arrowIconLeft}/>,
  'delete': () => <img alt="menu" src={deleteIcon} />,
  'upload': () => <img alt="menu" src={upload} />,
  'search': () => <img alt="menu" src={search} />,
  'menuIcon': () => <img alt="menu" src={menuIcon} />,
  'novaLogo': () => <img alt="logo" src={novaLogo} />,
  'sendButton': () => <img alt="send" src={send} />,
  'sendButtonInput': () => <img alt="send" src={sendInput} />,
  'sendButtonColor': () => <img alt="send" src={sendButtonColor} />,
  'file': () => <img alt="file" src={file} />,
  'addIcon': () => <img alt="add" src={addIcon} />,
  'searchIconLarge': () => <img alt="searchIconLarge" src={searchIconLarge} />,
  'plusIcon': () => <img alt="plusIcon" src={plusIcon} />,
  'tick': () => <img alt="plusIcon" src={tick} />,
  'upArrow': () => <img alt="plusIcon" src={upArrow} />,
  'downArrow': () => <img alt="plusIcon" src={downArrow} />,
  'attachment': () => <img alt="attachment" src={PaperClipColor} />,
  'personal_library_fileIcon': () => <img alt="attachment" src={folderAttachment} />,
  'dropDownArrow': () => <img alt="dropDownArrow" src={dropDownArrow} />,
  [COMPANY_INSTRUCTIONS]: () => <img alt="menu" src={adminLogs} />,
  [CUSTOM_CARDS]: () => <img alt="menu" src={customCards} />,
  [USER_LOGS]: () => <img alt="menu" src={userLogs} />,
  [LOGOUT]: () => <img alt="menu" src={logout} />,
  [`${HOME}_fileIcon`]: () => <img alt="attachment" src={PaperClipColor} />,
  [ANALYTICS]: () => <img alt="menu" src={analytics} />,
};