import { AnalyticsLineChart } from './AnalyticsLineChart';
import { Backdrop, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAnalyticsContext } from './AnalyticsContext';

export const AnalyticsWrapper = () => {
  const theme = useTheme();
  const { fetchData, formData, handleFieldChange, isLoading } = useAnalyticsContext();

  return (
    <>
      <AnalyticsLineChart
        fetchData={fetchData}
        formData={formData}
        handleFieldChange={handleFieldChange}
      />
      <Backdrop open={isLoading} style={{ zIndex: theme.zIndex.drawer + 1 }}>
        <CircularProgress style={{ color: theme.palette.primary.contrastText }} />
      </Backdrop>
    </>
  );
};