import { UserLogContextProvider } from "./UserLogContext";
import {UserLogsWrapper } from "./UserLogsWrapper";

export const UserLogs = () => {
    return (
      <>
         <UserLogContextProvider>
             <UserLogsWrapper/>
         </UserLogContextProvider>
      </>
    );
  };
  