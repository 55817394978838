import React, { useEffect } from "react";

import { useSSOContext } from "../../lib/contextProvider/SSOContext";

function Logout() {

  const { handleLogout } = useSSOContext();

  useEffect(() => {
    handleLogout();
  },[])

  return null;
}
export default Logout;