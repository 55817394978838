import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const AnalyticsContext = createContext();
export const useAnalyticsContext = () => {
  return useContext(AnalyticsContext);
};
export const AnalyticsContextProvider = ({ children }) => {
  const [formData, setFormData] = useState({
      selectedDateFrom: undefined,
      selectedDateTo: undefined
  });

  const [isLoading, setIsLoading] = useState(false);

  const transformData = (analyticsData) => {
    return analyticsData.data.records.reduce((accumulator, record) => {
      accumulator.push({
        name: record.date,
        "API Calls": record.total_records
      });
      return accumulator;
    }, []);
  };

  const fetchData = useCallback(async (from='2024-07-01', to='2024-07-31') => {
    setIsLoading(true);

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    try {
        const baseUrl = process.env.REACT_APP_CHAT_HISTORY_URL;
        const apiUrl = `${baseUrl}/analytics?from=${from}&to=${to}`;
        const response = await axios.get(apiUrl, { headers });
        if (!response.data) {
          setIsLoading(false);
          throw new Error('Failed to get response data');
        } else {
          setIsLoading(false);
          const transformedData = transformData(response);
          console.log(transformedData);
          return transformedData;
        }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error.message);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFieldChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const contextValue = {
    fetchData,
    formData,
    handleFieldChange,
    isLoading,
    setFormData,
    setIsLoading
  };

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}