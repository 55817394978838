import { Box, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { svgIcon } from '../../lib/assets/svg/images';
import { useUserLogContext } from './UserLogContext';
import {
    UserDateButton, UserLogsHeader,
    UserLogsSelect,
    UserLogsTypography
} from './UserLogsStyledComponents';

export const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiInputBase-input': {
        color: theme.palette.primary.contrastColor,
        backgroundColor: '#333',
        borderRadius: '3px',
        padding: '1px',
        background: theme.palette.primary.dark,
    },
}));

export const UserLogsFilter = () => {
    const { formData, handleFieldChange } = useUserLogContext();
    const { selectedCard, selectedUser, selectedDateBefore, selectedDateAfter } = formData;

    const theme = useTheme();

    return (
        <>
            <UserLogsHeader>User Logs</UserLogsHeader>
            <Box display="flex" alignItems="center" gap={2}>
                <UserLogsTypography>Card</UserLogsTypography>
                <UserLogsSelect
                    sx={{ fontSize: '10px' }}
                    value={selectedCard}
                    popupIcon={svgIcon['dropDownArrow']()}
                    getOptionLabel={(option) => (option ? option.label : "")}
                    options={[
                        { label: "Card 1" },
                        { label: "Card 2" },
                        { label: "Card 3" },
                    ]}
                    disableClearable
                    onChange={(event, newValue) => handleFieldChange('selectedCard', newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size={"medium"}
                            variant="filled"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    fontSize: "10px",
                                    height: '18.86px',
                                    borderRadius: "3px",
                                    textAlign: 'center',
                                    color: theme.palette.primary.contrastColor,
                                    padding: '0 8px'
                                },
                                disableUnderline: true,
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} style={{ fontSize: '10px' }}>
                            {option.label}
                        </li>
                    )}
                />
                <UserLogsTypography>User</UserLogsTypography>
                <UserLogsSelect
                    sx={{ fontSize: '10px' }}
                    value={selectedUser}
                    popupIcon={svgIcon['dropDownArrow']()}
                    getOptionLabel={(option) => (option ? option.label : "")}
                    options={[
                        { label: "User 1" },
                        { label: "User 2" },
                        { label: "User 3" },
                    ]}
                    disableClearable
                    onChange={(event, newValue) => handleFieldChange('selectedUser', newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size={"medium"}
                            variant="filled"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    fontSize: "10px",
                                    height: '18.86px',
                                    borderRadius: "3px",
                                    textAlign: 'center',
                                    color: theme.palette.primary.contrastColor,
                                    padding: '0 8px'
                                },
                                disableUnderline: true,
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} style={{ fontSize: '10px' }}>
                            {option.label}
                        </li>
                    )}
                />
                <UserLogsTypography>Before</UserLogsTypography>
                <div style={{ marginTop: '20px', background: `${theme.palette.primary.dark}`, width: "14%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                            value={selectedDateBefore}
                            onChange={(date) => handleFieldChange('selectedDateBefore', date)}
                        />
                    </LocalizationProvider>
                </div>
                <UserLogsTypography >After</UserLogsTypography>
                <div style={{ marginTop: '20px', background: `${theme.palette.primary.dark}`, width: "14%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CustomDatePicker
                            value={selectedDateAfter}
                            onChange={(date) => handleFieldChange('selectedDateAfter', date)}
                        />
                    </LocalizationProvider>
                </div>
                <UserDateButton>Apply Filter</UserDateButton>
            </Box>
        </>
    );
};

