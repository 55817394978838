import { Autocomplete, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DatePicker from 'react-datepicker';

export const UserLogsHeader = styled('div')(({ theme }) => ({
    color: theme.palette.primary.logoText,
    fontWeight: "400px",
    fontSize: "18px",
    lineHeight: "21.6px",
    marginTop: '20px'
}));

export const UserLogsTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastColor,
    fontWeight: "700px",
    fontSize: "20px",
    lineHeight: "24.2px",
    marginTop: '20px',
   
}));

export const UserLogsSelect = styled(Autocomplete)(({ theme }) => ({
   
   background: theme.palette.primary.dark,
    width: "16%",
    height: "18.86px",
    borderRadius: "3px",
    color: theme.palette.primary.contrastColor,
    lineHeight: "24.2px",
    marginTop: '20px'
    
    
}));

export const UserLogsTextField = styled(TextField)(({ theme }) => ({
 background: theme.palette.primary.dark,
  marginTop: '20px',
  width: "221.01px",
  height: "18.86px",
  borderRadius: "3px",
  color: theme.palette.primary.contrastColor,
  
}));
export const UserDatePicker = styled(DatePicker)(({ theme }) => ({
    background: theme.palette.primary.dark,
    marginTop: '20px',
    width: "112.15px",
    height: "18.86px",
    borderRadius: "3px",
    color: theme.palette.primary.contrastColor,
    textTransform: 'none'
}));

export const UserDateButton = styled('button')(({ theme }) => ({
    background: theme.palette.primary.contrastColor,
    marginTop: '20px',
    width:"11%",
    height:"20px",
    borderRadius: "3px",
    color: "#000000",
    fontWeight: "400px",
    fontSize: "12px",

}));
export const ChatContentMainDiv = styled('div')(({ theme }) => ({
    marginTop: '20px',
    width: "799px",
    height: "500px",
    border: `1px solid ${theme.palette.primary.contrastColor}`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        backgroundColor: 'white',
        width: '0.4em',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray',
        outline: '1px solid slategrey',
        borderRadius: '3px',
        height: '2px',
      },
}));

export const ChatSelectionMainDiv = styled('div')(({ theme }) => ({
   display:'flex',
   flexDirection:'column',
  marginTop: '20px',
    width: '304px',
    height: '500px',
    border: `1px solid ${theme.palette.primary.contrastColor}`,
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: 'white',
      width: '0.4em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      outline: '1px solid slategrey',
      borderRadius: '3px',
      height: '2px',
    },
  }));
  



export const UserChatSelectionDiv = styled('div')(({ theme ,isSelected}) => ({
    width: "304px",
    height: "110px",
    border: `1px solid ${theme.palette.primary.contrastColor}`,
    backgroundColor: isSelected ? '#133B3B' : 'transparent',
}));

export const CategoryType = styled('div')(({ theme }) => ({
    color: theme.palette.primary.whiteColor,
    fontSize:"14px",
    textAlign:"center",
    fontWeight:"bold",
     marginTop:"10px",
     marginBottom:"15px",
    width: "272px",
    justifycontent: "center"
}));

export const UserName = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.whiteColor,
    fontSize:"12px",
    fontWeight:"400px",
}));

export const UserLogsDate = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.whiteColor,
    fontSize:"12px",
    fontWeight:"bold",
    
    
}));
export const UserLogsTime = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.whiteColor,
    fontSize:"12px",
    fontWeight:"700px",
     marginBottom:"30px",
    
}));

export const ChatImageHistoryWrapper = styled('img')(({ theme }) => ({
  width: "327px",
  height: "187px",
  borderRadius:"5px"
}));


