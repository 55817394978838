export const NOVA_THEME = {
  palette: {
    backgroundColor: {
      gradient:
        "linear-gradient(180deg, #1B3333 0%, #204F4F 55.89%, #164848 100%)",
    },
    primary: {
      main: "#1e4e4e",
      dark: "#1B3333",
      light: "#EEEBDA",
      contrastColor: "#E8E5CE",
      productText: "#FCAA28",
      labelColor: "#FBAA96",
      logoText: "#FCAA28",
      success: "#37B653",
      error: "#D83949",
      radio: "#9D9A81",
      textField:"#133B3B",
      text:"#204F4F",
      whiteColor:"#FFFFFF"
    },
    secondary: {
      main: "#E0E0E0",
      dark: "#164848",
      searchBoxColor: "#C7C7C7",
      rowDark: "#F0F0F0",
      rowLight: "#F7F7F7",
    },
  },
  typography: {
    // fontFamily: "Ninety One Visuelt",

    chatText: {
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "21.78px",
      textAlign: "left",
    },
    subTitle: {
      fontSize: "24px",
      fontWeight: "600",
      lineHeight: "29.05px",
    },
    chatSenderName: {
      fontSize: "22px",
      fontWeight: "700",
      lineHeight: "26.63px",
      textAlign: "left",
      paddingBottom: "10px",
    },
    logo: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "29.05px",
      textAlign: "left",
    },
    subtitle1: {
      fontSize: 52,
      fontWeight: "400",
      lineHeight: "77.45px",
      textAlign: "left",
      marginTop: "35px",
    },
    subtitle2: {
      fontSize: "18px",
      fontWeight: "900",
      lineHeight: "21.78px",
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      fontSize: 16,
      fontWeight: "400",
      lineHeight: "19.36px",
      textAlign: "center",
    },
    footer: {
      fontSize: 14,
      fontWeight: "300",
      lineHeight: "16.94px",
    },
  },
};
