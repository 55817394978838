import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const SearchInput = ({ getCards, filterCards, handleSearchChange, searchTerm, setSearchTerm, setFilteredData, setSaveData }) => {
  const formRef = useRef(null);
  const onClear = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setSearchTerm('');
    filterCardsClick();
  }

  const filterCardsClick = () => {
    if (searchTerm === '') {
        setFilteredData([]);
        setSaveData([]);
        getCards();
        return;
    }
    const filteredCards = filterCards(searchTerm);
    setSaveData(filteredCards);
    setFilteredData(filteredCards);
}

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        filterCardsClick();
    } else if (event.key === 'Backspace' && searchTerm) {
        getCards();
    }
  }

  return (
    <form ref={formRef}>
      <TextField
        variant="outlined"
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        placeholder="Search and filter..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ cursor: 'pointer' }} onClick={filterCardsClick} />
              <IconButton
                  aria-label="clear search"
                  onClick={onClear}
                  edge="end"
              >
                  <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ '& .MuiInputBase-input': {
              color: '#E8E5CE',
            }, maxWidth: 400 }}
      />
    </form>
  );
};

export default SearchInput;