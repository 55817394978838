import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { UserLogsTypography } from '../UserLogs/UserLogsStyledComponents';
import { useCompanyInstructionsContext } from './CompanyInstructionsContext';
import { useSSOContext } from '../../lib/contextProvider/SSOContext';

export const CompanyInstructionsInput = () => {
  const {
    companyInstructions,
    getCompanyInstructions,
    setCompanyInstructions
  } = useCompanyInstructionsContext();

  const {
    username
  } = useSSOContext();

  useEffect(() => {
    if (!companyInstructions || companyInstructions.length === 0) {
      const index = username.indexOf('@');
      const name = username.substring(0, index);  
      getCompanyInstructions(name);
      console.log("Fetching Company Instructions from API");
    }
  }, [companyInstructions]);

  const onChange = (event) => {
    setCompanyInstructions(event.target.value);
  }

  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <UserLogsTypography>
        Set Company Instructions
      </UserLogsTypography>
      <>
        <TextField
          InputProps={{
            style: {
                color: theme.palette.primary.contrastColor,
            },
            disableUnderline: true,
          }}
          placeholder="Use British English when responding in English"
          multiline
          rows={10}
          maxRows={20}
          fullWidth={true}
          onChange={onChange}
          value={companyInstructions}
        />
      </>
    </Box>
  );
}