import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SideMenu from "../SideMenu/SideMenu";
import { routes } from '../../lib/routes/routes';
import { SideMenuProvider } from '../SideMenu/SideMenuContext';
import LandingPageChat from '../LandingPageChat/LandingPageChat';
import { HOME } from '../../lib/constants/paths';

const LandingPage = ( ) => {
  return (
    <Router>
      <SideMenuProvider>
      <SideMenu >
        <Routes>
        <Route exact path={HOME} element={<LandingPageChat />} />
          {routes.map(item => (
            <Route key={item.id} path={item.path} element={item.component} />
          ))}
        </Routes>
      </SideMenu>
      </SideMenuProvider>
    </Router>
  )
}
export default LandingPage;
