import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

import { useSSOContext } from '../../lib/contextProvider/SSOContext';

const CustomCardsContext = createContext();

export const useCustomCardsContext = () => useContext(CustomCardsContext);

export const CustomCardsContextProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [category, setCategory] = useState('All');
  const [cardData, setCardData] = useState({});
  const [filterName, setFilterName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  
  const {
    username
  } = useSSOContext();
  
  const getCards = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `${baseUrl}/api/cards_library`;

      const reqBody = {
        category: category,
        user_name: username
        // group_ids: groupIds
      }

      const response = await axios.post(apiUrl, reqBody,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

      if (!response.data) {
        setIsLoading(false);
        throw new Error('Failed to get response data');
      }

      for(let card in response.data.cards) {
        response.data.cards[card] = response.data.cards[card].filter((c) => {
          return c.is_active;
        });
      }

      setFilterName(['All', ...response.data.categories])
      setIsLoading(false);
      setCardData(response.data.cards);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const deleteCard = async (cardId) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/custom_card/delete/${cardId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log(`Custom card deleted successfully ${cardId}`);
      console.log('Response data:', response.data);
      if(response.data){
        getCards();
      }
      // Optionally, you can handle the response data here
    } catch (error) {
      console.error('There was a problem with the delete request:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClick = (item, index) => {
    setActiveIndex(index);
    // item = item === 'Custom Card' ? 'custom' : item === 'Team Document Libraries' ? 'DocumentLibraries' : item
    setCategory(item);
  };

  const filterCards = (filterData) => {
    for(let cardCategory in cardData) {
      cardData[cardCategory] = cardData[cardCategory].filter(card => {
        return card.card_name.toLocaleLowerCase().includes(filterData.toLocaleLowerCase());
      });
    }
    return cardData;
  };

  const contextValue = {
    deleteCard,
    getCards,
    handleSearchChange,
    handleClick,
    filterCards,
    searchTerm,
    setSearchTerm,
    activeIndex,
    filterName,
    isLoading,
    cardData,
    category
  };

  return (
    <CustomCardsContext.Provider value={contextValue}>
      {children}
    </CustomCardsContext.Provider>
  );
};
