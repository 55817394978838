import { ANALYTICS, COMPANY_INSTRUCTIONS, CUSTOM_CARDS, LOGOUT, USER_LOGS } from '../constants/paths';
import { Analytics } from '../../components/Analytics/Analytics';
import { CompanyInstructions } from '../../components/CompanyInstructions/CompanyInstructions';
import { CustomCards } from '../../components/CustomCards/CustomCards';
import Logout from '../../components/Logout/Logout';
import { UserLogs } from '../../components/UserLogs/UserLogs';

export const routes = [
  { id: 1, path: ANALYTICS, component: <Analytics /> },
  { id: 2, path: COMPANY_INSTRUCTIONS, component: <CompanyInstructions /> },
  { id: 3, path: CUSTOM_CARDS, component: <CustomCards /> },
  { id: 4, path: LOGOUT, component: <Logout /> },
  { id: 5, path: USER_LOGS, component: <UserLogs /> },
];