import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useSSOContext } from '../../lib/contextProvider/SSOContext';

const UserLogContext = createContext();

export const UserLogContextProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        selectedCard: '',
        selectedUser: '',
        selectedDateBefore: null,
        selectedDateAfter: null
    });
    const [isLoading, setIsLoading] = useState(false)
    const [chatInfo, setChatInfo] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [chatSideBarDetails, setChatSideBarDetails] = useState([]);

    const { username } = useSSOContext();

    const getFile = (body) => {
        try {
            const parsed = JSON.parse(body);
            return parsed?.filename;
        } catch (e) {
            // console.log(e)
        }
        return null;
    }
    const getBaseUrl = (body) => {
        try {
            const parsed = JSON.parse(body);
            if (parsed && parsed.files && parsed.files.length > 0) {
                const file = parsed.files[0];
                const base64url = file.base64url;
                const fileType = file.metadata && file.metadata.filetype
                return `data:${fileType};base64,${base64url}`;
            }
        } catch (e) {
            console.error("Error parsing JSON:", e);
        }
        return null;
    }
    const getBaseUrlNova = (body) => {
        try {
            const parsed = JSON.parse(body);
            if (parsed && parsed.filedetails && parsed.filedetails.length > 0) {
                const file = parsed.filedetails[0];
                const base64url = file.bloburl;
                return base64url
            }
        } catch (e) {
            console.error("Error parsing JSON:", e);
        }
        return null;
    }


    const getImage = (body) => {
        try {
            const parsed = JSON.parse(body);
            return parsed[1]?.image_url?.url;
        } catch (e) {
            console.error('Error parsing image URL:', e);
        }
        return null;
    };

    const getText = (body) => {
        try {
            const parsed = JSON.parse(body);
            return parsed[0]?.text
        } catch (e) {
            console.error('Error parsing text:', e);
            return body;
        }
    };

    const getQuery = (body) => {
        try {
            const parsed = JSON.parse(body);
            return parsed?.user_latest_query
        } catch (e) {
            console.error('Error parsing query:', e);
            return body;
        }
    };
    const transformRecord = (record) => {

        const index = record.user_name.indexOf('.');
        const name = record.user_name.substring(0, index);
        const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
        const file = getFile(record.request_body);
        const image = getImage(record.request_body);
        const text = getText(record.request_body);
        const query = getQuery(record.request_body);
        const baseUrl = getBaseUrl(record.request_body)
        if (file !== undefined && text === undefined && image === undefined && baseUrl === undefined) {
            return [
                {
                    role: 'user',
                    content: getQuery(record.request_body),
                    image: null,
                    isError: false,
                    filename: file || '',
                    userName: capitalizedName,
                },
                {
                    role: 'assistant',
                    content: record.response_body,
                    image: null,
                    isError: false,
                    filename: ''
                }
            ];
        }
        else if (image !== undefined) {
            return [
                {
                    role: 'user',
                    content: getText(record.request_body),
                    image: getImage(record.request_body) !== null ? getImage(record.request_body) : null,
                    isError: false,
                    filename: file || '',
                    userName: capitalizedName,
                },
                {
                    role: 'assistant',
                    content: record.response_body,
                    image: null,
                    isError: false,
                    filename: ''
                }
            ];
        }
        else if (baseUrl !== undefined) {
            return [
                {
                    role: 'user',
                    image: null,
                    content: getQuery(record.request_body),
                    isError: false,
                    filename: file || '',
                    userName: capitalizedName,
                },
                {
                    role: 'assistant',
                    content: getText(record.response_body),
                    image: null,
                    isError: false,
                    filename: file || ''
                }
            ];
        }
        else if (file === null && image === null) {
            return [
                {
                    role: 'user',
                    content: getText(record.request_body),
                    image: null,
                    isError: false,
                    filename: '',
                    userName: capitalizedName,
                },
                {
                    role: 'assistant',
                    content: record.response_body,
                    image: null,
                    isError: false,
                    filename: ''
                }
            ];
        }
    }
    const formatDateTime = (isoString) => {
        const date = new Date(isoString);

        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);

        const formattedDate = `${year}.${month}.${day}`;
        const formattedTime = `${hours}:${minutes}`;

        return {
            date: formattedDate,
            time: formattedTime
        };
    };

    const getChatSideBarDetails = (records) => {
        return records.map((record) => {
            const { date, time } = formatDateTime(record.updated_date);
            return {
                ...record,
                date: date,
                time: time
            };
        });
    };

    const fetchData = async () => {
        setIsLoading(true);
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        try {
            const baseUrl = process.env.REACT_APP_CHAT_HISTORY_URL;
            const apiUrl = `${baseUrl}?user_name=${username}&page=1&size=10&context=&from=&to=`;
            const response = await axios.get(apiUrl, { headers });
            if (!response.data) {
                setIsLoading(false);
                throw new Error('Failed to get response data');
            } else {
                setIsLoading(false);
                const chatData = getChatSideBarDetails(response.data.records);
                setChatSideBarDetails(chatData);
                if (chatData.length > 0) {
                    setSelectedIndex(0);
                    const transformedData = transformRecord(chatData[0]);
                    setChatInfo(transformedData);
                }

            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFieldChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    };
    const handleChatSelection = (index, item) => {
        setSelectedIndex(index)
        const transformedData = transformRecord(item);
        setChatInfo(transformedData);
    };

    const contextValue = {
        formData,
        setFormData,
        chatInfo,
        chatSideBarDetails,
        handleFieldChange,
        selectedIndex,
        setSelectedIndex,
        handleChatSelection,
        isLoading
    };

    return (
        <UserLogContext.Provider value={contextValue}>
            {children}
        </UserLogContext.Provider>
    );
};

export const useUserLogContext = () => {
    return useContext(UserLogContext);
};
