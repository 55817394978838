import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { SSOContextProvider } from './lib/contextProvider/SSOContext';
import LandingPage from './components/LandingPage/LandingPage';

function App() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        // Ensure that MSAL instance is initialized before calling handleRedirectPromise()
        await instance.initialize();
        const accounts = await instance.getAllAccounts()
        // Check if there's an ongoing interaction
        if (accounts.length > 0) {
          setIsLoading(false);
          return; // Exit if there's already an authenticated account
        }

        // If no user authenticated, try to acquire token silently
        if (accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read', 'openid', 'email', 'profile'],
            account: accounts[0] // Assuming only one account is available
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          // If still no accounts, initiate login
          await instance.loginRedirect({ scopes: ["User.Read", "openid", "email", 'profile'] });
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    handleRedirect();
  }, [instance]);

  return (
    <SSOContextProvider>
      <AuthenticatedTemplate>
        <LandingPage />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {null}
      </UnauthenticatedTemplate>
    </SSOContextProvider>
  );
}

export default App;
