import { UserLogsHeader } from '../UserLogs/UserLogsStyledComponents';
import React, { useEffect, useState } from 'react';
import SearchInput from './SearchInput';
import { CustomCardsGrid } from './CustomCardsGrid';
import { useCustomCardsContext } from './CustomCardsContext';

export default function CustomCardsWrapper() {
	const {
		getCards,
		filterCards,
		handleSearchChange,
		searchTerm,
		setSearchTerm,
		filterName,
    isLoading,
    cardData,
    category
	} = useCustomCardsContext();
	const [saveData, setSaveData] = useState(cardData);
  const [filteredData, setFilteredData] = useState({});

	return (
		<>
      <UserLogsHeader>Custom Cards</UserLogsHeader>
      <>
        <SearchInput
					getCards={getCards}
					filterCards={filterCards}
					handleSearchChange={handleSearchChange}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					setFilteredData={setFilteredData}
					setSaveData={setSaveData} />
      </>
			<CustomCardsGrid />
		</>
	)
}